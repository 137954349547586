































































import { Component, Ref, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import InfiniteLoading from 'vue-infinite-loading';
import { required } from 'vuelidate/lib/validators';

@Component({
  components: {
    InfiniteLoading,
  },
  validations: {
    selectedItems: {
      required,
    },
  },
})
export default class AutomationsEditFormFilters extends Vue {
  @Prop({ required: true, default: [] }) items!: any[];
  @Prop({ required: true, default: 'title' }) itemLabel!: string;
  @Prop({ required: true, default: 'Label' }) label!: string;
  @Prop({ required: true, default: [] }) selecteds!: any[];
  @Prop({ required: true, default: false }) loading!: boolean;
  @Prop({ default: '' }) placeholder!: string;

  @Ref('multiselect') multiselect: HTMLElement;

  selectedItems: any[] = [];
  openDropdown: boolean = false;

  @Watch('selectedItems')
  onChangeSelectedItems(value) {
    this.$emit('input', value);
  }

  @Emit()
  search(value: string) {
    this.openDropdown = true;
    return value;
  }

  created() {
    this.closeDropdownItems();
    this.initSelectedItems();
  }

  initSelectedItems() {
    this.selectedItems = this.selecteds.length ? this.selecteds : [];
  }

  closeDropdownItems() {
    document.addEventListener('mousedown', (e: any) => {
      if (this.multiselect?.contains(e.target)) return;
      this.openDropdown = false;
      this.$v.selectedItems.$touch();
    });
  }

  removeSelectedItem(index: number) {
    this.selectedItems.splice(index, 1);
    this.$v.selectedItems.$touch();
  }

  paginate($scope) {
    this.$emit('paginate', $scope);
  }
}
